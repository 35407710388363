<template>
	<div class="welcome">
		<div class="divflex">
			<div class="flexItem blue">
				<div class="flex1 ja-center">
					<img class="imglog" src="@/assets/img_new_top2.png" alt="" />
				</div>
				<div class="flex1 ja-center">
					<span class="border-p1" id="allorderCountThisMonth">{{cardfenxiao.allorderCountThisMonth}}</span>
					<span class="border-p2">本月订单</span>
				</div>
			</div>
			<div class="flexItem red">
				<div class="flex1 ja-center">
					<img class="imglog" src="@/assets/img_new_top1.png" alt="" />
				</div>
				<div class="flex1 ja-center">
					<span class="border-p1"
						id="orderCountThisMonth22">{{cardfenxiao.orderCountThisMonth-cardfenxiao.sysorderCountThisMonth}}</span>
					<span class="border-p2">本月微信订单</span>
				</div>
			</div>
			<div class="flexItem purple">
				<div class="flex1 ja-center">
					<img class="imglog" src="@/assets/img_new_top3.png" alt="" />
				</div>
				<div class="flex1 ja-center">
					<span class="border-p1" id="sysorderCountThisMonth">{{cardfenxiao.sysorderCountThisMonth}}</span>
					<span class="border-p2">本月系统订单</span>
				</div>
			</div>
			<div class="flexItem green">
				<div class="flex1 ja-center">
					<img class="imglog" src="@/assets/img_new_top4.png" alt="" />
				</div>
				<div class="flex1 ja-center">
					<span class="border-p1" id="commissionThisMonth">{{cardfenxiao.commissionThisMonth}}</span> <span
						class="border-p2">可提现佣金</span>
				</div>
			</div>
			<div class="flexItem yellow">
				<div class="flex1 ja-center">
					<img class="imglog" src="@/assets/img_new_top5.png" alt="" />
				</div>
				<div class="flex1 ja-center">
					<span class="border-p1" id="preRechargeBalance">{{cardfenxiao.preRechargeBalance}}</span> <span
						class="border-p2">预充值余额</span>
				</div>
			</div>
		</div>

		<el-row :gutter="20" style="margin-top: 20px;">
			<el-col :span="5">
				<div class="grid-content  tongji">
					<div class="tongji_col">
						<el-row class="row">
							<el-col :span="24">
								<!-- <div class="title-box"><span class="m-title">网卡分销统计</span></div> -->
								<div class="title-col"><span style="padding-left: 10px;">网卡分销统计</span></div>
							</el-col>
						</el-row>
						<el-row class="rowone">
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="cardCount1">{{cardfenxiao.cardCount}}</p>
									<p>网卡总数</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="cardCountDist">{{cardfenxiao.cardCountDist}}</p>
									<p>已分销卡</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="cardCountUndist">{{cardfenxiao.cardCountUndist}}</p>
									<p>未分销卡</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="grid-content tongji ">
					<div class="tongji_col">
						<el-row class="row">
							<el-col :span="24">
								<div class="title-col"><span style="padding-left: 10px;">网卡充值统计</span></div>
							</el-col>
						</el-row>
						<el-row class="rowone">
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="cardCount2">{{cardfenxiao.cardCount}}</p>
									<p>网卡总数</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="cardCountRecharged">{{cardfenxiao.cardCountRecharged}}
									</p>
									<p>已充值卡</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="cardCountUnRecharged">
										{{cardfenxiao.cardCountUnRecharged}}
									</p>
									<p>未充值卡</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="grid-content tongji">
					<div class="tongji_col">
						<el-row class="row">
							<el-col :span="24">
								<div class="title-col"><span style="padding-left: 10px;">订单统计</span></div>
							</el-col>
						</el-row>
						<el-row class="rowone">
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="orderCountThisMonth1">
										{{cardfenxiao.orderCountThisMonth}}
									</p>
									<p>本月订单数</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="orderCountRefundThisMonth">
										{{cardfenxiao.orderCountRefundThisMonth}}
									</p>
									<p>本月退款订单数</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content ">
									<p style="color: #09A2E3" id="orderCountPreMonth">{{cardfenxiao.orderCountPreMonth}}
									</p>
									<p>上月订单数</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="grid-content tongji">
					<div class="tongji_col">
						<el-row class="row">
							<el-col :span="24">
								<div class="title-col"><span style="padding-left: 10px;">订单金额</span></div>
							</el-col>
						</el-row>
						<el-row class="rowone">
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="orderAmountThisMonth">
										{{cardfenxiao.orderAmountThisMonth}}
									</p>
									<p>本月订单金额</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="orderRefundAmountThisMonth">
										{{cardfenxiao.orderRefundAmountThisMonth}}
									</p>
									<p>本月退款订单金额</p>
								</div>
							</el-col>
							<el-col :span="8">
								<div>
									<p style="color: #09A2E3" id="orderAmountPreMonth">
										{{cardfenxiao.orderAmountPreMonth}}
									</p>
									<p>上月订单金额</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="grid-content tongji">
					<div class="tongji_col">
						<el-row class="row">
							<el-col :span="24">
								<div class="title-col"><span style="padding-left: 10px;">更多统计</span></div>
							</el-col>
						</el-row>
					
						<el-row class="rowone">
							<el-col :span="24" class="col-flex">
								<a href="javascript:;" @click="seeMorePool">点击查看</a>
							</el-col>
						</el-row>
					
						</div>
				</div>
				
			</el-col>
		</el-row>

		<el-row :gutter="20">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box"><span class="m-title">近7日订单数</span></div>
					<div id="chartMainId" style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box"><span class="m-title">近7日订单数</span></div>
					<el-table :data="tableData" ref='table' style="width: 100%;height: 500px;">
						<el-table-column prop="date" label="日期" width="120">
							<template v-slot:default="scope">
								{{millsToStr(scope.row.statisDate,false,false)}}
							</template>
						</el-table-column>
						<el-table-column prop="orderCount" label="订单数" width="120">
						</el-table-column>
						<el-table-column prop="orderAmount" label="订单金额">
						</el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="20" style="margin-top: 20px;">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box"><span class="m-title">前十代理商销售榜单</span></div>
					<div id="chartMainId3" style="height:650px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box"><span class="m-title">前十代理商销售榜单</span></div>
					<el-table :data="tableData1" ref='table' style="width: 100%;height: 650px;">
						<el-table-column prop="createDatetime" label="日期">
						</el-table-column>
						<el-table-column prop="name" label="排名">
							<template v-slot="scope">
								{{named(scope.$index)}}

							</template>

						</el-table-column>
						<el-table-column prop="agentName" label="姓名">
						</el-table-column>
						<el-table-column prop="orderCount" label="当日订单数">
						</el-table-column>
						<el-table-column prop="orderMoney" label="当日订单金额">
						</el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="20" style="margin-top: 20px;">
			<el-col :span="24">
				<div class="col3">
					<div class="title-box"><span class="m-title">历史月充值额统计</span><el-button @click="exportMonthRecharge" size="mini" class="themed-button right-button" type="danger">导 出</el-button></div>
					<div id='monthRecharge' style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		agentstatistics,
		agentranking,
		getStaticsAdmin,
		staticsAdminExport,
	} from "@/api/welcome.js"
	import {
		getMenuList,
	} from "@/api/login.js"
	import echarts from 'echarts'
	import store from '@/store'
	export default {
		data() {
			return {
				tableData: [],
				tableData1: [],
				cardfenxiao: {},
				xdata: [],
				seriesData: [],
				myBarChart: "",
				myChart: "",
				titleArr: [],
				valueArr: [],
				monthRechargeChart:"",
			}
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					this.monthRechargeChart.resize()
					if (!!this.myChart) {
						this.myChart.resize()
					}
					if (!!this.myBarChart) {
						this.myBarChart.resize()
					}
				})()
			}

		},
		created() {
			this.getMenuList()
			this.init()
			this.getMonthRecharge()
		},
		methods: {
			seeMorePool(){
              this.$router.push("/agentHome/moreStatics")
			},
			getMenuList() {
				getMenuList({}).then(menuStr => {
					let agentRecharge = menuStr.agentRecharge
					if (agentRecharge && agentRecharge !== "" &&
						agentRecharge.balanceMoney &&
						agentRecharge.balanceMoney !== "" &&
						agentRecharge.warnMoney &&
						agentRecharge.warnMoney !== "") {
						if (parseFloat(agentRecharge.balanceMoney) < parseFloat(agentRecharge.warnMoney)) {
							this.$alert("尊敬的客户：你的预充值已经小于" +
								agentRecharge.warnMoney +
								"元，为了不影响正常使用，请提前充值。", '提示', {
									confirmButtonText: '确定',
								}).then(()=>{
									if(store.getters.hasNew){
										this.$confirm('您有新的公告通知，请及时查看','消息提示',{
												confirmButtonText: '阅读',
												cancelButtonText: '取消',
												type: 'warning'
											}).then(res=>{
												this.$router.push({
													path:'/agentManagement/agentAnnounce'
												})
											})
									}
								})
						}else{
								if(store.getters.hasNew){
									this.$confirm('您有新的公告通知，请及时查看','消息提示',{
												confirmButtonText: '阅读',
												cancelButtonText: '取消',
												type: 'warning'
											}).then(res=>{
												this.$router.push({
													path:'/agentManagement/agentAnnounce'
												})
										})
								}
						
						}
					}else{
						if(store.getters.hasNew){
									this.$confirm('您有新的公告通知，请及时查看','消息提示',{
												confirmButtonText: '阅读',
												cancelButtonText: '取消',
												type: 'warning'
											}).then(res=>{
												this.$router.push({
													path:'/agentManagement/agentAnnounce'
												})
										})
								}
					}
				})
			},
			named(num) {
				let nums = parseInt(num) + 1;
				return "第" + nums + "名"
			},
			init() {

				agentstatistics({
					version: 1
				}).then(res => {
					this.tableData = res.orderList
					this.cardfenxiao = res.agentStatisMainEntity
					console.log(res)
					for (let i = res.orderList.length - 1; i >= 0; i--) {
						let item = res.orderList[i]
						this.xdata.push(this.millsToStr(item.statisDate, false, false))
						this.seriesData.push(item.orderCount)
					}
					this.loadchartMainId()
				})
				agentranking({}).then(res => {
					this.tableData1 = res.rows
					for (var i = 0; i < res.rows.length; i++) {
						var record = res.rows[i];
						this.titleArr.push(record.agentName);
						this.valueArr.push(record.orderCount);
					}
					this.loadchartMainId3()
				})
			},
			millsToStr(dateMills, hasTime, hasSec) {
				if (!dateMills) return "";
				var date = new Date(dateMills);
				var str = "";

				var mon = date.getMonth() + 1;
				if (mon < 10) {
					mon = "0" + mon;
				} else {
					mon = "" + mon;
				}
				var day = date.getDate();
				if (day < 10) {
					day = "0" + day;
				} else {
					day = "" + day;
				}

				str += date.getFullYear();
				str += ("-" + mon);
				str += ("-" + day);

				if (hasTime) {
					var hour = date.getHours();
					if (hour < 10) {
						hour = "0" + hour;
					} else {
						hour = "" + hour;
					}

					var min = date.getMinutes();
					if (min < 10) {
						min = "0" + min;
					} else {
						min = "" + min;
					}
					str += (" " + hour);
					str += (":" + min);

					if (hasSec) {
						var sec = date.getSeconds();
						if (sec < 10) {
							sec = "0" + sec;
						} else {
							sec = "" + sec;
						}
						str += (":" + sec);
					}

				}


				return str;
			},
			loadchartMainId() {
				const myChart = echarts.init(document.getElementById('chartMainId'));
				this.myChart = myChart
				let option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.xdata
					},
					yAxis: {
						type: 'value'
					},

					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ff6b01',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.seriesData,
						type: 'line',
						areaStyle: {}
					}]

				};
				myChart.setOption(option, true);

			},
			loadchartMainId3() {
				const myChart3 = echarts.init(document.getElementById('chartMainId3'));
				this.myBarChart = myChart3
				let option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#AE0000',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.valueArr,
						type: 'line',
						areaStyle: {}
					}]
				};
				myChart3.setOption(option, true);
			},

			getMonthRecharge(){
				getStaticsAdmin().then(res=>{
					let amount = res.amount.reverse()
					let month = res.month.reverse()
					this.$nextTick(function(){
						this.showMonthRechargeChart(amount,month)
					})
				})
				
				
			},

			showMonthRechargeChart(amount,month){
				let dom = document.getElementById("monthRecharge");
				let myChart = echarts.init(dom)
				this.monthRechargeChart = myChart
				const option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: month
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true,
									color:"#000"
								}
							}

						},
						color: '#E0EBFD',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: amount,
						type: 'line',
						areaStyle: {}
					}]
				}
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
				
			},

			exportMonthRecharge(){
				staticsAdminExport().then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},

		}
	}
</script>

<style>
	.welcome .divflex {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}

	.welcome .flexItem {
		flex: 1;
		height: 150px;
		border-radius: 5px;
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-right: 15px;
	}

	.welcome .imglog {
		width: 70px;
		height: 70px;
	}

	.welcome .yellow {
		background-color: #FFCC33;
	}

	.welcome .blue {
		background-color: #04A1E8;
	}

	.welcome .red {
		background-color: #FE685A;
	}

	.welcome .purple {
		background-color: #9966FF;
	}

	.welcome .green {
		background-color: #99CC00;
	}

	.welcome .border-p2 {
		padding: 5px 0;
		font-size: 20px;
		color: #fff
	}

	.welcome .border-p1 {
		font-size: 24px;
		color: white;
		padding: 5px 0;
	}

	.welcome .flex1 {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.welcome .tongji {
		width: 100%;
		height: 200px;
		background-color: #F7F9FC;
	}

	.welcome .tongji_col {
		background: #fff;
		width: 95%;
		height: 80%;
		border-radius: 10px;
		border: 1px solid #F5F5F6;
		box-shadow: 1px 2px 2px #F5F5F6;
		display: flex;
		flex-direction: column;
	}

	.welcome .title-col {
		font-size: 16px;
		border-left: 8px solid #09A2E3;
		margin: 10px;
	}

	.welcome .row {
		flex: 1
	}

	.welcome .rowone {
		flex: 3;
		/* display: flex;
		align-items: center; */
		margin-top: 24px;
	}

	.welcome .el-col-8 {
		text-align: center;
		font-size: 16px;
	}

	.welcome .el-col-6 {
		font-size: 16px;
	}

	.welcome .el-col-24 {
		border-bottom: 1px solid #ddd;
	}

	.welcome .col3 {
		background-color: #F7F9FC;
		/* font-size: 16px !important; */
	}

	.welcome .el-table td,
	.welcome .el-table th.is-leaf {
		border-right: .5px solid #e7e7e7 !important;
		border-bottom: .5px solid #e7e7e7 !important;
		background-color: #F8F8F8;
	}
	.right-button{
		position: absolute;
		top:9px;
		right: 50px;
	}
	.welcome .col-flex{text-align: center;}
	.welcome .col-flex.el-col-24{border-bottom:none}
</style>
